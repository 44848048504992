.gridbox {
    justify-content: center;
    display: grid;
    max-width: 1440px;
    width: 100%;
    gap: 65px 25px;
    grid-template-columns: repeat(auto-fill, 280px);
    margin: 2% 0;
    border-radius: 5px;
    z-index: 2;
    margin-top: 4rem;
    scrollbar-color: rgb(141, 40, 40) green;
    scrollbar-width: thin;
    min-width: 300px;
}



.gridboxs {
    display: grid;
    position: relative;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    justify-content: center;
    width: 100%;
    max-width: 1080px;
    z-index: 2;
    scrollbar-color: rgb(141, 40, 40) green;
    scrollbar-width: thin;
    margin: 5rem 0;
}

.home1,
.home2,
.home3,
.home4,
.home5 {
    overflow: hidden;
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px; */
}

.home1 {
    position: relative;
    width: 100%;
    /* height: 500px; */
    transition: 0.3s ease-out;
    grid-area: 1 / 1 / 4 / 3;
}

.home1 img {
    object-fit: cover;

}

.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #19191988;
}

.home1:hover .overlay {
    opacity: 1;
}

.home1 .overlay h3 {
    color: white;
    font-size: 20px;
    position: absolute;
    bottom: 10px;
    left: 10px;
    text-align: center;
    font-weight: 700;
    font-size: 20px;
}

.home1 .overlay p {
    color: white;
    font-size: 20px;
    position: absolute;
    bottom: 30px;
    left: 30px;
    text-align: center;
}




.home2 {
    position: relative;
    width: 100%;
    /* height: 150px; */
    transition: 0.3s ease-out;
    grid-area: 1 / 1 / 4 / 4;
}

.home2 img {
    display: block;
    width: 100%;
    /* height: 150px; */
    object-fit: cover;
}

.overlay1 {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #19191988;
}

.home2:hover .overlay1 {
    opacity: 1;
}

.home2 .overlay1 h3 {
    color: white;
    font-size: 20px;
    position: absolute;
    bottom: 10px;
    left: 10px;
    text-align: center;
    font-weight: 700;
    font-size: 20px;
}

.home2 .overlay1 p {
    color: white;
    font-size: 20px;
    position: absolute;
    bottom: 5px;
    left: 10px;
    text-align: center;
}


.home3 {
    position: relative;
    width: 100%;
    /* height: 150px; */
    transition: 0.3s ease-out;
    grid-area: 1 / 1 / 4 / 3;
}

.home3 img {
    display: block;
    width: 100%;
    /* height: 150px; */
    object-fit: cover;
}

.overlay2 {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #19191988;
}

.home3:hover .overlay2 {
    opacity: 1;
}

.home3 .overlay2 h3 {
    color: white;
    font-size: 20px;
    position: absolute;
    bottom: 10px;
    left: 10px;
    text-align: center;
    font-weight: 700;
    font-size: 20px;
}

.home3 .overlay2 p {
    color: white;
    font-size: 20px;
    position: absolute;
    bottom: 5px;
    left: 10px;
    text-align: center;
}




.home4 {
    position: relative;
    width: 100%;
    height: 250px;
    transition: 0.3s ease-out;

}

.home5 {
    position: relative;
    width: 100%;
    height: 250px;
    transition: 0.3s ease-out;

}

.home4 img {
    display: block;
    width: 100%;
    height: 250px;
    object-fit: cover;
}

.home5 img {
    display: block;
    width: 100%;
    height: 250px;
    object-fit: cover;
}

.overlay3 {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #19191988;
}

.home4:hover .overlay3 {
    opacity: 1;
}

.home5:hover .overlay3 {
    opacity: 1;
}


.home4 .overlay3 h3 {
    color: white;
    font-size: 20px;
    position: absolute;
    bottom: 10px;
    left: 10px;
    text-align: center;
    font-weight: 700;
    font-size: 20px;
}

.home5 .overlay3 h3 {
    color: white;
    font-size: 20px;
    position: absolute;
    bottom: 10px;
    left: 10px;
    text-align: center;
    font-weight: 700;
    font-size: 20px;
}

.home4 .overlay3 p {
    color: white;
    font-size: 20px;
    position: absolute;
    bottom: 5px;
    left: 10px;
    text-align: center;
}

.home5 .overlay3 p {
    color: white;
    font-size: 20px;
    position: absolute;
    bottom: 5px;
    left: 10px;
    text-align: center;
}














.div1 {
    grid-area: 1 / 1 / 3 / 3;
}

.div2 {
    grid-area: 1 / 3 / 2 / 4;
}

.div3 {
    grid-area: 1 / 4 / 2 / 5;
}

.div4 {
    grid-area: 1 / 4 / 2 / 5;
}

.div5 {
    grid-area: 2 / 3 / 4 / 5;
}

.grid {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.projects {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    cursor: pointer;
}

.projects h3 {
    position: absolute;
    bottom: 20PX;
    z-index: 99999;
    padding: 15px 10px;
    color: white;
    font-weight: 500;
}

.projects p {
    position: absolute;
    bottom: 0px;
    padding: 15px 10px;
    color: white;
    z-index: 20;

}

.ssss {
    margin-top: 4rem;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.home1 {
    grid-area: 1 / 1 / 3 / 3;
}

.home2 {
    grid-area: 1 / 3 / 2 / 4;
}

.home3 {
    grid-area: 1 / 4 / 2 / 5;
}

.home4 {
    grid-area: 2 / 3 / 3 / 3;
}



@media only screen and (max-width: 800px) {
    .gridboxs {
        align-items: center;
        justify-content: center;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(4, 1fr);
        grid-column-gap: 50px;
        grid-row-gap: 20px;
        justify-content: center;
        display: flex;
        flex-direction: column;
        height: 1000px;
        width: 100%;
        max-width: 1080px;
        z-index: 2;
        scrollbar-color: rgb(141, 40, 40) green;
        scrollbar-width: thin;
        /* min-width: 300px;     */
        padding: 0;
    }

    .home1 {
        grid-area: 1 / 1 / 2 / 2;
    }

    .home2 {
        grid-area: 2 / 1 / 3 / 2;
    }

    .home3 {
        grid-area: 3 / 1 / 4 / 2;
    }

    .home1 {
        height: 300px;
    }

    .home2 {
        height: 300px;
    }

    .home3 {
        /* height: 300px; */
    }

    .home4 {
        /* height: 300px; */
    }

    .home1 img {
        /* height: 300px; */
    }

    .home2 img {
        /* height: 300px; */
    }

    .home3 img {
        /* height: 300px; */
    }

    .home4 img {
        /* height: 300px; */
    }
}