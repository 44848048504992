.about {
  margin-bottom: 80px;
}
.aboutmain {
  display: flex;
  align-items: center;
  padding: 15px 25px;
}
.aboutcontent {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center;
}
.about .heading {
  text-align: left;
  width: 100%;
  margin-bottom: 30px;
}
.about p {
  font-size: 15px;
  padding-right: 30px;
  margin-bottom: 20px;
  line-height: 30px;
}
@media screen and (max-width: 768px) {
  .aboutmain {
    flex-direction: column-reverse;

  }
  .about p {
    padding: 0;
  }
  .about .container img {
    margin-top: 50px;
  }
}