.imageofheader {
    width: 100%;
    min-height: 400px;
    background-color: rgba(0, 128, 0, 0.252);
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../../../../public/images/property/majidi/majidihero.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.imageofheaderkhlood {
    width: 100%;
    min-height: 400px;
    background-color: rgba(0, 128, 0, 0.252);
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../../../../public/images/KHLOOD.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}
.imageofheaderhigh {
    width: 100%;
    min-height: 400px;
    background-color: rgba(0, 128, 0, 0.252);
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../../../../public/images/property/highclass/hero.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}
.imageofheadersylv {
    width: 100%;
    min-height: 400px;
    background-color: rgba(0, 128, 0, 0.252);
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../../projects/Projectlocatons/sylvana.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.imageofheaderwedd {
    width: 100%;
    min-height: 400px;
    background-color: rgba(0, 128, 0, 0.252);
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../../../../public/images/alwed2.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.imageofheaderbianca {
    width: 100%;
    min-height: 400px;
    background-color: rgba(0, 128, 0, 0.252);
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../../../../public/images/Bianca-1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.imageofheaderdubai {
    width: 100%;
    min-height: 400px;
    background-color: rgba(0, 128, 0, 0.252);
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../../images/Dub.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.imageofheaderturkey {
    width: 100%;
    min-height: 400px;
    background-color: rgba(0, 128, 0, 0.252);
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../../images/istanbull.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.imageofheadererbil {
    width: 100%;
    min-height: 400px;
    background-color: rgba(0, 128, 0, 0.252);
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../../images/erbil.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.imageofheaderkirkuk {
    width: 100%;
    min-height: 400px;
    background-color: rgba(0, 128, 0, 0.252);
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../../images/Kir.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.imageofheadermasour {
    width: 100%;
    min-height: 400px;
    background-color: rgba(0, 128, 0, 0.252);
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../almansour/MansourCity1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.imageofheaderempire {
    width: 100%;
    min-height: 400px;
    background-color: rgba(0, 128, 0, 0.252);
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../empire/Empire1Pearls.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.imageofheadernorth {
    width: 100%;
    min-height: 400px;
    background-color: rgba(0, 128, 0, 0.252);
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../northholland/Northholland1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.imageofheaderqaywan {
    width: 100%;
    min-height: 400px;
    background-color: rgba(0, 128, 0, 0.252);
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../qaywan/Qaiwan2.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.imageofheaderlalav {
    width: 100%;
    min-height: 400px;
    background-color: rgba(0, 128, 0, 0.252);
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../lalav/LalavCityView2.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.imageofheaderskyland {
    width: 100%;
    min-height: 400px;
    background-color: rgba(0, 128, 0, 0.252);
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../skyland/skyland3.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}
.imageofheadersamana {
    width: 100%;
    min-height: 400px;
    background-color: rgba(0, 128, 0, 0.252);
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../samana/home.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.imageofheaderlagoon {
    width: 100%;
    min-height: 400px;
    background-color: rgba(0, 128, 0, 0.252);
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../lagoon/Lagoons1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.imageofheaderazizi {
    width: 100%;
    min-height: 400px;
    background-color: rgba(0, 128, 0, 0.252);
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../aziziriver/AziziRiveria2.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.imageofheaderbay2 {
    width: 100%;
    min-height: 400px;
    background-color: rgba(0, 128, 0, 0.252);
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../bay2/Bay2_3.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.imageofheadermosul {
    width: 100%;
    min-height: 400px;
    background-color: rgba(0, 128, 0, 0.252);
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../../images/Mos.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.imageofheaderbaghdad {
    width: 100%;
    min-height: 400px;
    background-color: rgba(0, 128, 0, 0.252);
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url("../../images/Bag.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.imagealwed {
    background-image: url("../../../../public/images/alwed2.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 250px;
}

.imageklood {
    background-image: url("../../../../public/images/KHLOOD.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 250px;
}

.imagebianca {
    background-image: url("../../../../public/images/Bianca-1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 250px;
}
.imagesamana {
    background-image: url("../../projects/samana/1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 250px;
}

.imagemajidi {
    background-image: url(".././majedy/4.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 250px;
}

.citybaghdad {
    background-image: url("../../images/Bag.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 250px;
}

.citymosul {
    background-image: url("../../images/Mos.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 250px;
}

.citydubai {
    background-image: url("../../images/Dub.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    height: 250px;
}

.cityerbil {
    background-image: url("../../images/erbil.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 250px;
}

.citykirkuk {
    background-image: url("../../images/Kir.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 250px;
}
.cityturkey {
    background-image: url("../../images/turkey.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 250px;
}

.projectlalav {
    background-image: url("../Projectlocatons/LALAV1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 250px;
}

.projectnorth {
    background-image: url("../Projectlocatons/North.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 250px;
}

.projectalmansour {
    background-image: url("../Projectlocatons/almansour.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 250px;
}

/* .projectalmansour {
    background-image: url("../Projectlocatons/almansour.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 250px;
} */

.projectqaywan {
    background-image: url("../Projectlocatons/qaiwan.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 250px;
}

.projectmajidi {
    background-image: url("../Projectlocatons/MajidiV.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 250px;
}

.projectwedd {
    background-image: url("../Projectlocatons/wedd.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 250px;
}

.projectmansour {
    background-image: url("../Projectlocatons/almansour.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 250px;
}
.projecthigh {
    background-image: url("../Projectlocatons/highcalssblog.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 250px;
}

.projectkhlood {
    background-image: url("../Projectlocatons/Khlood1.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 250px;
}

.projectdamac {
    background-image: url("../Projectlocatons/lagoons.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 250px;
}

.projectdamac2 {
    background-image: url("../Projectlocatons/Bay2.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 250px;
}
.projectsylvana {
    background-image: url("../Projectlocatons/sylvana-min.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 250px;
}

.projectazizi {
    background-image: url("../Projectlocatons/rivera.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 250px;
}

.projectskyland {
    background-image: url("../Projectlocatons//Skyland.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 250px;
}

.mainmajidi {
    width: 100%;
    display: flex;
    justify-content: center;
}

.imageofheader h1 {
    color: rgb(238, 238, 238);
}

.imageofheaderkhlood h1 {
    color: rgb(238, 238, 238);
}
.imageofheaderhigh h1 {
    color: rgb(238, 238, 238);
}
.imageofheadersylv h1 {
    color: rgb(238, 238, 238);
}

.imageofheaderwedd h1 {
    color: rgb(238, 238, 238);
}

.imageofheaderbianca h1,
.imageofheadererbil h1,
.imageofheaderkirkuk h1,
.imageofheadermosul h1,
.imageofheaderbaghdad h1,
.imageofheaderdubai h1,
.imageofheaderturkey h1,
.imageofheaderbay2 h1,
.imageofheaderazizi h1,
.imageofheaderskyland h1,
.imageofheadersamana h1,
.imageofheaderlalav h1,
.imageofheaderqaywan h1,
.imageofheadernorth h1,
.imageofheaderempire h1,
.imageofheadermasour h1,
.imageofheaderlagoon h1 {
    color: rgb(238, 238, 238);
    z-index: 10;
}

.navleft {
    justify-content: flex-start;
    width: 200px;
    margin: 20px;
    padding: 0 20px;
    border-right: 1px solid rgba(42, 42, 42, 0.1);
}

.navp a {
    margin: 20px 0 10px 10px;
}

.navp {
    display: flex;
    flex-direction: column;
}

.content {
    width: 100%;
    max-width: 924px;
    margin: 20px;
    padding: 0 20px;
}

.twothink {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    padding: 0 0 20px 0;
    align-items: center;
    border-bottom: 1.5px solid rgba(128, 128, 128, 0.407);
}

.slideimage {
    width: 100%;
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(59, 167, 56, 0.337);
    margin: 30px 0 60px 0;
}


.overview {
    display: flex;
    padding: 50px;
    width: 100%;
    border: 1px solid rgba(42, 42, 42, 0.1);
    margin: 15px 0 60px;
}

.borderandmargin:not(:first-child) {
    border-left: 1px solid rgb(42, 42, 42);
    padding: 0 20px;
}

.borderandmargin {
    margin-left: 2rem;
}

.borderandmargin h5 {
    padding: 4px 0;
}



.pdis {
    margin: 20px 0 60px 0
}

.parents {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;
    margin-bottom: 60px;
}

.div4 {
    position: relative;
    grid-area: 1 / 1 / 2 / 2;
    background-color: rgba(59, 167, 56, 0.337);
    width: 100%;
    height: 100%;
    transition: 0.3s ease-out;
}

.div5 {
    position: relative;
    grid-area: 1 / 2 / 2 / 3;
    background-color: rgba(59, 167, 56, 0.337);
    width: 100%;
    height: 100%;
    /* min-height: 250px; */
    transition: 0.3s ease-out;
}

.div6 {
    position: relative;
    grid-area: 2 / 1 / 3 / 3;
    background-color: rgba(59, 167, 56, 0.337);
    width: 100%;
    height: 100%;
    transition: 0.3s ease-out;
}







.div4 img {
    display: block;
    width: 100%;
    height: 250px;
}

.BROCHURE {
    background-color: #916f4e;
    color: white;
    padding: 8px 14px;
    border-radius: 4px;
    cursor: pointer;
}


.overlays {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #19191988;
}

.div4:hover .overlays {
    opacity: 1;
}

.div4 .overlays h3 {
    color: white;
    position: absolute;
    bottom: 10px;
    left: 10px;
    text-align: center;
}

.div4 .overlays p {
    color: white;
    position: absolute;
    bottom: 10px;
    left: 10px;
    text-align: center;
}



.div5 img {
    display: block;
    width: 100%;
    height: 250px;
}

.div5:hover .overlays {
    opacity: 1;
}

.div5 .overlays h3 {
    color: white;
    position: absolute;
    bottom: 10px;
    left: 10px;
    text-align: center;
}

.div5 .overlays p {
    color: white;
    position: absolute;
    bottom: 10px;
    left: 10px;
    text-align: center;
    font-weight: 700;
}



.div6 img {
    display: block;
    width: 100%;
    height: 250px;
}

.div6:hover .overlays {
    opacity: 1;
}

.div6 .overlays h3 {
    color: white;
    position: absolute;
    bottom: 10px;
    left: 10px;
    text-align: center;
}

.div6 .overlays p {
    color: white;
    position: absolute;
    bottom: 10px;
    left: 10px;
    text-align: center;
}

.tset {
    width: 100%;
    height: 400px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.map {
    width: 100%;
    height: 250px;
    background-color: rgba(59, 167, 56, 0.337);
    margin: 30px 0 60px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}



.imageproperty {
    margin: 30px 0 60px 0;
    width: 100%;
    overflow: hidden;
    flex-wrap: nowrap;
    overflow-x: auto;
    display: flex;
    float: left;
}

.imageproperty img {
    width: 250px;
    height: 250px;
    margin-right: 1rem;
}


.iconsss {
    display: flex;
    flex-direction: row;
    padding: 50px;
    width: 100%;
    border: 1px solid rgba(42, 42, 42, 0.1);
    justify-content: space-between;
    margin: 30px 0;
}

.row {
    display: flex;
    margin-top: 30px;
}

.iconss {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    margin-left: 15px;
    align-content: center;
}




@media screen and (max-width:1200px) {
    .navleft {
        display: none;
    }
}

@media screen and (max-width:700px) {
    .iconsss {
        flex-direction: column;
    }
}


@media screen and (max-width:550px) {
    .overview {
        flex-direction: column;
    }
}


































/* .heroalewd {
    background-image: url("../../../../public/images/KHLOOD.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    min-height: calc(100vh - 60px);
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.herobianca {
    background-image: url("../../../../public/images/Bianca-1.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        min-height: calc(100vh - 60px);
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        
}
.heroalwedd {
    background-image: url("../../../../public/images/sdfaf.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    min-height: calc(100vh - 60px);
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.heroMajidi {
    background-image: url("../../../../public/images/property/majidi/majidihero.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    min-height: calc(100vh - 60px);
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.herobianca h1 {
    color: #fff;
    font-size: 30px;
    z-index: -1;
}
.heroMajidi h1 {
    color: #fff;
    font-size: 30px;
    z-index: 1;
}
.heroalwedd h1 {
    color: #fff;
    font-size: 30px;
    z-index: 1;
}
.heroalwedd p {
    color: #fff;
    opacity: 0.8;
    font-size: 24;
    z-index: 2;
}
.herobianca p {
    color: #fff;
    opacity: 0.8;
    font-size: 24;
    z-index: -1;
}
.heroalewd h1 {
    color: #fff;
    font-size: 30px;
    z-index: 1;
}
.heroalewd p {
    color: #fff;
    opacity: 0.8;
    font-size: 24;
    z-index: 2;
}

.fade-in-section {
    opacity: 0;
    transform: translateY(20vh);
    visibility: hidden;
    transition: opacity 0.6s ease-out, transform 1.2s ease-out;
    will-change: opacity, visibility;
}
.b {
    font-weight: 800;
    color: black;
    margin: 15px 0 5px 0;
}

.fade-in-section.is-visible {
    opacity: 1;
    transform: none;
    visibility: visible;
}
main {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.test {
    padding: 20px;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    max-width: 1024px;
}
.test img {
    max-width: 500px;
    border-radius: 25px;
}
.paraghraf {
    width: 100%;
     padding: 20px 15px;
    text-align: start;
        display: flex;
            flex-direction: column;
            align-items: center;
}
.revers {
    display: flex;
    flex-direction: row-reverse;
}
.formpage {
    max-width: 388px;
    padding: 20px 20px;
}
.formss {
    background-color: transparent;
    display: flex;
}
.formss input {
    background-color: #fff;
    margin: 0px 20px;
}

@media screen and (max-width: 800px) {
    .heroalewd h1 {
        font-size: 18px;
    }
    .formss {
        background-color: transparent;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .formss input {
        margin: 10px 0px;
    }
    .heroalewd p {
        font-size: 12px;
    }

    .heroalewd {
        height: calc(110vh - 60px);
    }
    .test {
        flex-direction: column;
    }
} */