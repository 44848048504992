* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Open Sans', sans-serif;
  background-color: #f8f8f8;
  overflow-x: hidden;
}

.container {
  max-width: 100%;
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}

a {
  text-decoration: none;
  color: #000;
  text-transform: capitalize;
}

ul {
  list-style-type: none;
}

button {
  border-radius: 5px;
  padding: 10px 20px;
  background: #916f4e;
  border: none;
  color: #fff;
  cursor: pointer;
  font-weight: bold;
  margin-bottom: 0.0rem;
}

button i {
  margin-right: 5px;
}

.heading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100%;
  padding: 2rem 4rem;
  margin: 15px 0;
}

.fsearch {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  width: 100%;
  padding: 0rem 0rem;
  margin: 0;
}

.heading h1 {
  color: #2d3954;
  font-weight: 600;
  text-transform: capitalize;
  font-size: 20px;
}

.heading p {
  color: #72809d;
}

textarea,
input {
  border: none;
  outline: none;
  background: none;
  background-color: white;
}

.background {
  padding: 80px 0;
  position: relative;
  background: #f7f9fc;
}

.grid5 {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 30px;
}

label {
  color: #2d3954;
  font-size: 14px;
}

h4 {
  font-weight: 500;
  margin-top: 10px;
}

.shadow {
  box-shadow: 0 0 20px 0 rgb(112 121 138 / 18%);
  overflow: hidden;
  border-radius: 26px;
}

.padding {
  padding: 20px 0;
}

.grid3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}

img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.btn2 {
  border-radius: 10px;
  font-size: 20px;
}

.grid4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
}

.btn3 {
  background: #ff6922;
  border-radius: 50px;
}

.btn4 {
  background-color: #916f4e;
  color: #fff;
  margin-left: 10px;
}

.btn1 {
  margin-bottom: 0;
}

.btn5 {
  border-radius: 50px;
  padding: 20px 40px;
  color: #916f4e;
  font-size: 20px;
  font-weight: 400;
  border: 5px solid #27ae601f;
  background: #fff;
}

.back {
  height: 400px;
  position: relative;
}

.back img {
  height: 40vh;

  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  object-fit: cover;
}


.back::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 40vh;
  background: rgba(17, 40, 72, 0.629);
  z-index: -1;
}

.back .container {
  color: #fff;
  padding: 120px 0;
}

.back h1 {
  /* font-size: 30px; */
  /* font-weight: 500; */
}

.mb {
  margin-bottom: 80px;
}

.imglocation {
  width: 20px;
  height: 20px;
}

.cinput {
  padding: 15px 20px;
}

textarea {
  padding: 15px 10px;
}

select:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

.sss {
  justify-content: center;
  display: grid;
  max-width: 1440px;
  width: 100%;
  gap: 20px;
  grid-template-columns: repeat(auto-fill, 270px);
  margin: 2% 0;
  border-radius: 5px;
  z-index: 2;
  margin-top: 4rem;
  scrollbar-color: rgb(141, 40, 40) green;
  scrollbar-width: thin;
  min-width: 300px;
  display: flex;
}

.zoom:hover {
  /* back */
}

.pagenotfound {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80vh;
  width: 100%;
}


.mtop {
  margin-top: 50px;
  width: 100%;
  max-width: 1024px;
}

/* .ddd {
  padding: 30px;
  margin-right: 90px;
} */
.imageabout {
  max-width: 1400px;
}

.discription p {
  margin: 0 5px;
}

.country {
  color: #fff;
  background-color: #27ae5fe7;
  border-radius: 10px;
  margin-right: 10px;
  padding: 5px 12px;
  height: 35px;
}

.type {
  color: #fff;
  background-color: #27ae5fe7;
  padding: 10px 12px;
  border-radius: 10px;
  margin-right: 10px;
  padding: 5px 12px;
  height: 35px;
}

.footerleft {
  display: flex;
  flex-direction: row;
}

.video-responsive {
  overflow: hidden;
  width: 100%;
  margin: 40px 0px;
}

.video-responsive iframe {
  width: 100%;
  min-height: 100vh;
}

.flexs {
  display: flex;
  padding: 20px 0;
  align-self: flex-end;
  width: 150px;
}

.priversy {
  padding: 2rem 4rem;
}

.priversy ul {
  padding: 1rem 2rem;
}

.priversy ul li {
  padding: 10px 2rem;
  list-style: normal;
}

.priversy h2,
.priversy h3 {
  margin: 20px 0;
}

.dropdownss {
  background: white;
  border: none;
  position: relative;
  cursor: pointer;
  color: #000;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background-color: #0000006d;
  z-index: 10000000;
  background-color: rgba(0, 0, 0, 0.608);
  z-index: 9999;
  justify-content: center;
  align-items: center;
  display: flex;
}

.popupimage {
  background-image: url('./components/images/dubaidubai.jpg');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  }

  .invitep {
    width: 60%;
    font-size: 30; 
    direction: rtl; 
    color: #ea8c59; 
    font-weight:700;
  }

@media screen and (max-width: 500px) {
  select {
    width: 100%;
  }
}

@media screen and (max-width: 800px) {

  .grid4,
  .grid3,
  .grid5 {
    grid-template-columns: repeat(2, 1fr);
  }

  .heading {
    width: 100%;
  }

  .container {
    max-width: 90%;
  }

  .back::after,
  .back img,
  .back {
    height: 50vh;
    min-height: 50vh;
  }

}

.linedown {
  display: flex;
}

@font-face {
  font-family: 'arabic';
  src: url('./fonts/arabicfont.otf');
  font-weight: 100;
}

.ar {
  font-family: "arabic";
}

.options {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  max-height: 0;
  overflow: hidden;
  background-color: #fff;
  transition: max-height 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 99;
}

.options.open {
  max-height: 150px;
  overflow-y: scroll;
}

.option {
  padding: 10px;
  transition: background-color 0.3s ease;
}

.option:hover {
  background-color: #f0f0f0;
}

/* Hide the scrollbar */
.options::-webkit-scrollbar {
  width: 0;
  background-color: transparent;
}
