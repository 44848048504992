.footerContact {
  background-color: #916f4e;
  padding: 40px 0;
  color: #fff;
}
.footerContact h1 {
  font-size: 40px;
}
footer {
  background-color: #916f4e;
  padding: 50px 0;
  color: #fff;
}
footer .container .footerleft {
  margin: 0 0 20px 0;
  flex-direction: column;
  align-items: center;
}
footer .container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  text-align: center;
}
footer img {
  width: 150px;
}
footer h2 {
  font-weight: 500;
    margin: 10px 10px;
}
footer p {
  color: white;
  margin: 20px 0;
}
footer input {
  background-color: #fff;
  padding: 17px;
  width: 100%;
  max-width: 364px;
  border-radius: 5px;
  margin-right: 10px;
}
footer h3 {
  font-weight: 500;
  margin-bottom: 30px;
}
footer ul {
  display: block;
}
footer ul li {
  display: block;
  color: white;
  margin-bottom: 20px;
}
.forms {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 40px; 
  /* margin-top: -12px; */
  background-color: #916f4e;
}

.forms button {
  background-color: #27ae5fe7;
}

.formsinput {
  width: 80%;
  height: 85%;
  margin-right: 15px;  
}
.legal {
  text-align: center;
  padding: 20px;
  background: #916f4e;
  color: #916f4e;
  border-top: 1px solid #916f4e;
}
@media screen and (max-width: 800px) {

    footer .container .footerleft {
      margin: 0 0 20px 0;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    footer .container {
      flex-direction: column;
    }
    footer h3 {
      margin-top: 20px;
    }
    .forms {
      flex-direction: row;
      margin-bottom: 50px;
    }
}

