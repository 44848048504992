.parentsP {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    padding: 4rem 0;
}

.parentserbil {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    padding: 4rem 0;
}

.parentsbaghdad {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    padding: 4rem 0;
}

.parentskirkuk {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    padding: 4rem 0;
}

.parentsmosul {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    padding: 4rem 0;
}

.parentsdubai {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(1, 1fr);
    grid-column-gap: 40px;
    grid-row-gap: 40px;
    padding: 4rem 0;
}


.div1P {
    grid-area: 1 / 1 / 2 / 2;
    position: relative;
}

.div2P {
    grid-area: 2 / 1 / 3 / 2;
    position: relative;
}

.div3P {
    grid-area: 1 / 2 / 2 / 3;
    position: relative;
}

.div4P {
    grid-area: 2 / 2 / 3 / 3;
    position: relative;
}

.div5P {
    /* grid-area: 2 / 2 / 3 / 3; */
    position: relative;
}

.div4P:hover .overlays {
    opacity: 1;
}

.div5P:hover .overlays {
    opacity: 1;
}

.div4P .overlays h3 {
    color: white;
    position: absolute;
    bottom: 10px;
    left: 10px;
    text-align: center;
}

.div5P .overlays h3 {
    color: white;
    position: absolute;
    bottom: 10px;
    left: 10px;
    text-align: center;
}

.div4P .overlays p {
    color: white;
    position: absolute;
    bottom: 10px;
    left: 10px;
    text-align: center;
    /* letter-spacing: 3.2px; */
}

.div5P .overlays p {
    color: white;
    position: absolute;
    bottom: 10px;
    left: 10px;
    text-align: center;
    /* letter-spacing: 3.2px; */
}

.div1P:hover .overlays {
    opacity: 1;
}

.div1P .overlays h3 {
    color: white;
    position: absolute;
    bottom: 10px;
    left: 10px;
    text-align: center;
}

.div1P .overlays p {
    color: white;
    position: absolute;
    bottom: 10px;
    left: 10px;
    text-align: center;
    /* letter-spacing: 3.2px; */
}

.div2P:hover .overlays {
    opacity: 1;
}

.div2P .overlays h3 {
    color: white;
    position: absolute;
    bottom: 10px;
    left: 10px;
    text-align: center;
}

.arfrom {
    direction: rtl;
}

.div2P .overlays p {
    color: white;
    position: absolute;
    bottom: 10px;
    left: 10px;
    text-align: center;
    /* letter-spacing: 3.2px; */
}

.div3P:hover .overlays {
    opacity: 1;
}

.div3P .overlays h3 {
    color: white;
    position: absolute;
    bottom: 10px;
    left: 10px;
    text-align: center;
}

.div3P .overlays p {
    color: white;
    position: absolute;
    bottom: 10px;
    left: 10px;
    text-align: center;
    /* letter-spacing: 3.2px; */
}

.whatsapp {
    width: 40px;
    height: 40px;
}




.overlays {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #19191988;
    z-index: 20;
}

.absoluteForm {
    position: fixed;
    width: 100%;
    height: calc(100% - 60px);
    background-color: rgba(0, 0, 0, 0.608);
    z-index: 9999;
    justify-content: center;
    align-items: center;
    display: flex;
}

.brochurForm {
    width: 90%;
    min-width: 90%;
    height: 90%;
    min-width: 90%;
    /* background-image: url(); */
    display: flex;
    justify-content: center;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 18px 20px 35px -2px rgba(0, 0, 0, 0.2);
}

.closeButton {
    position: absolute;
    right: 50px;
    top: 20px;
    font-size: 30px;
    cursor: pointer;
}

.brochurLeft {
    width: 50%;
    padding: 20px 30px;
}

.majdiimages {
    background-image: url('./majedy/MAJDI@0.75x.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.khloodimages {
    background-image: url('./khlood/khloodoo.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.biancaimages {
    background-image: url('./bianca/Bianca-32@0.33x.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.alweddimages {
    background-image: url('./alwedd/alewd@0.5x.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.brochurRight {
    width: 50%;
    padding: 60px 40px;
    display: flex;
    flex-direction: column;
    background-color: white;
    position: relative;
    align-items: center;
    justify-content: center;
}

.brochurRight form {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 100%;
    min-height: 100%;
    margin: 0;
    padding: 10px;
}

.brochurRight form input {
    border: 1px solid rgb(133, 133, 133);
    border-radius: 4px;
    height: 38px;
}

.brochurRight form label {
    margin: 15px 0 0px 0;
}

.btnsubmit {
    background-color: #7a5e41;
    color: white;
    transition: ease-in-out 300ms;
    cursor: pointer;
}

.btnsubmit:hover {
    background-color: rgb(57, 244, 88);
    color: black;
}

.ssss {
    /* margin : 2rem 0 */
}

@media only screen and (max-width: 900px) {
    .closeButton {
        right: 10px;
        top: 10px;
    }

    .brochurLeft {
        display: none;
    }

    .brochurRight {
        width: 100%;
        padding: 30px 20px;
    }
}

.locations {
    width: 100%;
    height: 100px;
    background-color: green;
}

@media only screen and (max-width: 500px) {
    .closeButton {
        right: 10px;
        top: 10px;
    }

    .brochurLeft {
        display: none;
    }

    .brochurRight {
        width: 100%;
        padding: 30px 20px;
    }

    .parentsP,
    .parentsbaghdad,
    .parentsdubai,
    .parentserbil,
    .parentskirkuk,
    .parentsmosul {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(5, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 40px;
        padding: 4rem 0;
    }

    .div1P {
        grid-area: 1 / 1 / 2 / 2;
    }

    .div2P {
        grid-area: 2 / 1 / 3 / 2;
    }

    .div3P {
        grid-area: 3 / 1 / 4 / 2;
    }

    .div4P {
        grid-area: 4 / 1 / 5 / 2;
    }
}