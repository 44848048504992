.backsearch {
    width: 100%;
    min-height: 20vh;
    margin: 0rem;
    display: flex;
    align-items: center;
    background-color: #ffffff;
    border-bottom: 1px solid rgb(123, 123, 123);
}

.fromsearch {
    display: flex;
    justify-content: space-around;
    padding-right: 16px;
    padding-left: 16px;
    margin-right: auto;
    margin-left: auto;
    align-items: center;
    /* background-color: red; */
    width: 100%;
}

.fromsearch label {
    margin-bottom: 20px;
    padding-left: 5px;
    font-weight: 100;
    font-size: 12px;
}

.fromsearch select {
    margin-bottom: 20px;
    padding-left: 5px;
    font-weight: 700;
    padding: 10px 20px;
}

.projectName {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
}

.line {
    background-color: rgb(29, 29, 29);
    height: 60px;
    width: 1.4px;
}

.btnsearch {
    background-color: #916f4e;
    color: white;
    font-weight: 700;
    margin-left: 10px;
    max-width: 200px;
}

option {
    font-size: 18px;
    padding: 5px 0;
    word-spacing: 30px;
    position: absolute;
    top: 100%;
    z-index: 99;
}

.dropdownss {
    position: relative;
    width: 200px;
}

.selected-option {
    padding: 10px;
    background-color: #f0f0f0;
    cursor: pointer;
}

