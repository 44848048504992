.each {
    width: 100%;
    height: 70vh;
}

main {
    padding: 10px 15px;
    background-color: #fafafa;
    padding: 4rem;
}
.container1 {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    position: relative;
}
.container2 {
    padding-top: 3rem;
    display: flex;
    width: 100%;
    justify-content: space-between;

    
}
.location {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 20px 0;
}
.location .span {
    padding: 10px 10px;
}
.con2 {
    padding: 10px 15px;
}

.images {
    width:100%;
    height: 100%;
    padding: 25px 0;
}
.left-main{
    width: 65%;
}
.left-con2 {
    width: 65%;
    background-color: #FFFF;
    padding: 10px 15px;
    display: flex;
    flex-direction: column;
    height: 400px;
    justify-content: center;
}
.form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.left {
    width: 65%; 
    display: grid;
    background-color: #FFFF;
}
.right {
    width: 30%;
    padding: 20px 15px;
    align-items: center;
    height: 600px;
}

.nameagent {
    width: 20%;
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.imgagent {
    position: relative;
    width: 100%;
    height: 80%;
}
.imgagent img {
    object-fit: contain;
}
.icons {
    width: 95%;
    display: flex;
    align-items: center;
}
.icons i {
    width: 40px;
}
.cons2{
    display: grid;
    grid-template-columns: auto auto auto;
    padding: 20px 0;
    align-items: center;
    height: 200px;
}
.cons2 span {
    flex-direction: row;
    text-align: center;
    display: flex;
    width: 100%;
}
.cons2 i {
    font-size: 100px;
}
.inside{
    width: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    padding: 0 0 0 10px;
}
.inside p { 
    padding: 0 0 0 5px;   
}

.icons i {
    font-size: 25px;
}


.iamge {
    width: 100%;
    height: 90%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

.is {
    height: 125px;
    width: 25px;
    background-color: #916f4e;

}
.name {
    background-color: #FFFF;
    padding: 10px 15px;
} 
.right {
    display: flex;
    flex-direction: column;
    background-color: #ffff;
}
.ontheright {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.iconp {
    width: 95%;
    margin-left: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.agent {
    background-color: #FFFF;
    position: relative;
    /* max-width: 320px; */
    height: 500px;
    width: 30%;
}

@media only screen and (max-width: 990px) {
    .container1 {
        flex-direction: column;
    }
    .left-con2 {
        width: 100%;
        height: 300px;
    }
    .container2 {
        flex-direction: column;
    }
    .right , .left-main {
        width: 100%;
        padding: 0;
    }
    main {
        padding: 10px;
    }
    .cons2 {
        display: flex;
        flex-direction: column;
    }
    .cons2 span {
        padding: 5px 0;
    }
}