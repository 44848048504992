.awards {
  background-color: #916f4e;
  color: white;
  text-align: center;
}
.awards .heading {
  display: flex;
  flex-direction: column-reverse;
}
.awards .heading p {
  color: white;
}
.awards .heading h1 {
  color: #fff;
  font-size: 30px;
  font-weight: 400;
}
.awards .icon {
  width: 100px;
  height: 100px;
  line-height: 100px;
  margin: auto;
  margin-top: 10px;
  background: white;
  border-radius: 4% 50%;
  font-size: 32px;
  color: #916f4e;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}
.awards h1 {
  font-size: 50px;
}
.awards p {
  color: white;
}
.icon img {
  width: 60px;
  height:60px;
}