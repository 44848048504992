.inputs {
  padding: 0px 10px;
  border: none;
  outline: none;
  background-color: #fff;

  -webkit-background-clip: unset;
}

.heromain {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.advance {
  position: absolute;
  right: 0;
  top: 10px;
  padding: 10px 15px;
  border: none;
}

.advance a {
  color: white;
  font-weight: 600;
}


.hero {
  position: relative;
  min-height: calc(80vh - 60px);
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.herovedio {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: calc(80vh - 60px);
  position: absolute;
  z-index: -1;
}

.herocontain {
  width: 80%;
}

.herocontain h2 {
  font-size: 90px;
  color: #7a5c3e;
  /* height: 110px; */
}

.herocontain h3 {
  color: #7a5c3e;
  font-size: 20px;
}

.herocontain .heroborder {
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px 10px;
  border-radius: 30px;
  margin-top: 10px;

  background-color: #7a5c3e;
}

.herocontain .heroborder h3 {
  color: #fff;
}


.hero .container {
  padding-top: 15%;
}

.hero h1 {
  color: #fff;
  font-size: 32px;
  z-index: 1;
}

.hero p {
  color: #fff;
  opacity: 0.8;
  font-size: 24;
  z-index: 2;
}

form {
  background-color: #fff;
  border-radius: 5px;
  margin-top: 1rem;
  padding: 0 20px;
  z-index: 2;
}

form input {
  /* padding: 10px 0; */
  width: 100%;
  border: 1px solid rgba(128, 128, 128, 0.2);
  /* margin-top: -2px; */
  border-radius: 5px;
  height: 100%;
}

form span {
  font-size: 14px;
  color: grey;
}

input::placeholder {
  /* font-size: 17px;
  color: black; */
}

form .box {
  padding: 15px;
  border-left: 1px solid rgba(128, 128, 128, 0.2);
}

form .box:nth-child(1) {
  border-left: none;
}

form h4 {
  font-weight: 500;
}

.flexsearch {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 20px;
}


.login-box {
  width: 100%;
  min-width: 260px;
  max-width: 400px;
  padding: 40px;
  background: rgba(0, 0, 0, 0.501);
  box-sizing: border-box;
  box-shadow: 0 15px 25px rgba(0, 0, 0, .6);
  border-radius: 10px;
}

.login-box h2 {
  margin: 0 0 30px;
  padding: 0;
  color: #fff;
  text-align: center;
}

.login-box .user-box {
  position: relative;
}

.login-box .user-box input {
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  margin-bottom: 30px;
  border: none;
  border-bottom: 1px solid #fff;
  outline: none;
  background: transparent;
}

.login-box .user-box label {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 0;
  font-size: 16px;
  color: #fff;
  pointer-events: none;
  transition: .5s;
}

.login-box .user-box input:focus~label,
.login-box .user-box input:valid~label {
  top: -20px;
  left: 0;
  color: white;
  font-size: 12px;
}

.ardirection {
  direction: rtl;
  text-decoration: rtl;
}

.login-box form a {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  color: white;
  font-size: 16px;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: .5s;
  margin-top: 40px;
  letter-spacing: 4px
}

.login-box a:hover {
  background: #91704e75;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 5px #03e9f4,
    0 0 25px #03e9f4,
    0 0 50px #03e9f4,
    0 0 100px #03e9f4;
}

.login-box a span {
  position: absolute;
  display: block;
}


.login-box a span:nth-child(1) {
  top: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(90deg, transparent, #03e9f4);
  animation: btn-anim1 1s linear infinite;
}

@keyframes btn-anim1 {
  0% {
    left: -100%;
  }

  50%,
  100% {
    left: 100%;
  }
}

.login-box a span:nth-child(2) {
  top: -100%;
  right: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(180deg, transparent, #03e9f4);
  animation: btn-anim2 1s linear infinite;
  animation-delay: .25s
}

@keyframes btn-anim2 {
  0% {
    top: -100%;
  }

  50%,
  100% {
    top: 100%;
  }
}

.login-box a span:nth-child(3) {
  bottom: 0;
  right: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(270deg, transparent, #03e9f4);
  animation: btn-anim3 1s linear infinite;
  animation-delay: .5s
}

.login-box input::placeholder {
  color: rgb(176, 176, 176);
  font-weight: 300;
}

@keyframes btn-anim3 {
  0% {
    right: -100%;
  }

  50%,
  100% {
    right: 100%;
  }
}

.login-box a span:nth-child(4) {
  bottom: -100%;
  left: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(360deg, transparent, #03e9f4);
  animation: btn-anim4 1s linear infinite;
  animation-delay: .75s
}

@keyframes btn-anim4 {
  0% {
    bottom: -100%;
  }

  50%,
  100% {
    bottom: 100%;
  }
}

form {
  background-color: transparent;
}

.hero h1,
.hero p {
  color: #ffffff;
}

.herologo {
  align-items: center;
  margin-top: 20px;
  display: flex;
}

.herologo img {
  width: 100px;
}

.zestborder {
  border-right: 3px solid #7a5c3eed;
  width: 4px;
  height: 60px;
  margin: 0 10px;
}
#invitep {
  width: 60%; 
  font-size: 30px; 
  direction:rtl; 
  color:#ea8c59;
  font-weight:700
}
#azizilogo {
  width: 460px; 
  object-fit: contain;
  cursor: auto;
}


@media screen and (max-width: 800px) {
  #invitep {
    width: 90%; 
    font-size: 25px; 
    direction:rtl; 
    color:#ea8c59;
    font-weight:700
  }
  #invitetop {
    display: flex;
    flex-direction: column;
    margin-top: 80px;
  }
  #none {
    display: none;

  }
  .hero h1 {}

  .hero p {
    font-size: 12px;
  }

  .hero {
    height: calc(80vh - 60px);
  }

  .hero .container {
    padding-top: 10%;
  }

  form {
    display: flex;
    flex-wrap: wrap;
  }
  
  form .box {
    border-left: none;
    width: 50%;
  }

  form input {
    width: 100%;
  }

  .herocontain h2 {
    font-size: 45px;
    /* height: 45px; */
  }
}
#invitepcontent {
  height: 70vh; 
  width: 90% ; 
  align-items: flex-end; 
  display: flex; 
  flex-direction: column; 
  justify-content:center;
}

@media screen and (max-width: 800px) {
  #invitepcontent {
    width: 90% ; 
  }
  .last {
    width: 100%;
  }
  .hero h1 {
    /* font-size: 70px; */
  }
}

@media screen and (max-width: 1100px) {
  .flexsearch {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 20px;
  }
  
  @media screen and (max-width: 600px) {
    #invitep {
      width: 90%; 
      font-size: 20px; 
    }
    #invitetop {
      display: flex;
      flex-direction: column;
      margin-top: 80px;
    }
    #logofade {
      display: none;
    }
    #azizilogo {
      width: 100%; 
      object-fit: contain;
      cursor: auto;
    }

  }
  @media screen and (max-width: 400px) {
    #margininvite {
      margin-top: -4rem;
    }
    #invitep {
      width: 90%; 
      font-size: 15px; 
    }
    .hero h1 {
      /* font-size: 58px; */
    }

    .herocontain h2 {
      font-size: 50px;
      /* height: 55px; */
    }

    .herocontain h3 {
      font-size: 15px;
      /* height: 25px; */
    }

    .herocontain .h3 {
      font-size: 15px;
      line-height: 20px;
      margin-bottom: 10px;
    }
  }
}

.search {
  width: 30px;
  height: 30px;
}