.heroevents {
    width: 100%;
    min-height: calc(100vh - 60px);
    background-image: url('./heropage.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    padding: 50px;
    justify-content: space-between;
}
.leftevents {
    display: flex;
    height: 20vh;
    align-items: center;
}
.rightevents {
    color: white;
    font-size: 25px;
    direction: rtl;
}
.rightevents h1 {
    color: white;
    font-size: 70px;
}
.rightevents h2, .rightevents h3 {
    color: white;
    padding-top: 15px;
    padding-bottom: 15px;
}
.logozs {
    height: 50px;
    width: auto;
    padding: 10px;
}

.btneventheros {
    background-color: white;
    color: rgb(51, 51, 174);
    width: 200px;
    text-align: center;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    font-weight: 900;
}

.containerones {
    display: flex;
    justify-content: center;
    width: 100%;
    min-height: 70vh;
    align-items: center;

}

.containerlefts {
    direction: rtl;
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 10px;
}

.containerrights {
    direction: rtl;
    width: 40%;
}

.containerlefts h2 {
    font-size: 40px;

}

.containerlefts p { 
    padding-bottom: 25px;
    padding-top: 15px;
    font-size: 20px;
}

.firtimgevents {
    object-fit: contain;
    max-height: 400px;
}

.btneventfirsts {
    background-color: #7a5e41;
    width: 230px;
    text-align: center;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
    color: white;
    font-size: 25px;
    font-weight: 900;
    z-index: 99;
}
.marg {
    margin-top: 20px;
}

.reversess {
    display: flex;
    direction: rtl;
}



@media only screen and (max-width: 1200px) {
    .rightevents {
        color: white;
        font-size: 20px;
        direction: rtl;
    }
    .rightevents h1 {
        color: white;
        font-size: 50px;
    }
  }


@media only screen and (max-width: 1100px) {
    .containerones {
        flex-direction: column-reverse;
    }
    .containerlefts {
        width: 90%;
    }
    
    .containerrights {
        width: 90%;
    }
    .containerones {
        display: flex;
        justify-content: center;
        width: 100%;
        min-height: 100vh;
        align-items: center;
    
    }
  }


@media only screen and (max-width: 924px) {
    .heroevents {
        display: flex;
        flex-direction: column;
        justify-content: normal;
        align-items: center;

    }
    .rightevents {
        color: white;
        font-size: 15px;
        direction: rtl;
        text-align: center;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
    }
  }


@media only screen and (max-width: 375px) {
    .rightevents {
        color: white;
        font-size: 15px;
        direction: rtl;
        text-align: center;
        justify-content: center;
        align-items: center;
        display: flex;
        flex-direction: column;
    }
    .rightevents h1 {
        color: white;
        font-size: 30px;
    }
  }