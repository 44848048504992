header {
  background-color: rgb(255, 255, 255);
  height: 60px;
  box-shadow: 0 5px 30px rgb(0 22 84 / 10%);
  position: sticky;
  top: 0;
  z-index: 8888;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
}

header img {
  width: 70px;
}


header a {
  margin-left: 15px;
  transition: 0.5s;
}

header a:hover {
  color: black;
}

header span {
  width: auto;
  padding: 0px 6px;
  background: #916f4e;
  border-radius: 50px;
  color: #ffffff;
  margin-right: 4px;
  font-weight: 400;
}

header h4 {
  font-weight: 500;
  margin-right: 20px;
}

header .toggle {
  display: none;
}

.animationunderline {
  position: relative;
  color: #000;
  text-decoration: none;
}
.animationunderline:hover {
  color: #916f4e;
}
.animationunderline::before  {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 1px;
  bottom: 0;
  left: 0;
  background-color: #916f4e;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}
.animationunderline:hover::before {
  transform: scaleX(1);
}


@media screen and (max-width: 920px) {
  .animationunderline {
    position: relative;
    color: #ffffff;
    text-decoration: none;
  }
  .animationunderline:hover {
    color: #4fc744;
  }
  .animationunderline::before  {
    content: "";
    position: absolute;
    display: block;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: #4fc744;
    transform: scaleX(0);
    transition: transform 0.3s ease;
  }
  .animationunderline:hover::before {
    transform: scaleX(1);
  }
  .btnsearch {
    margin-top: 20px;
    margin-left: 0;
  }

  .lang {
    color: #ffffff;
  }

  header {
    padding: 0;
  }

  header .button {
    display: none;
  }

  header .nav .flex {
    display: none;
  }

  header .toggle {
    display: block;
  }

  header .small {
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: #916f4e;
    padding: 20px;
    transition: all ease-in-out 250ms;
  }

  header li a {
    display: inline-block;
    margin-bottom: 20px;
    color: #fff;
  }

  .fromsearch {
    display: flex;
    flex-direction: column;

  }

  .fromsearch li {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
  }

  .line {
    display: none;
  }

  .backsearch {
    height: 30rem;
  }

}

.humberger {
  color: #fff;
}

.close {
  width: 15px;
}
